import { Bar } from 'vue-chartjs'
import ColorCycler from './ColorCycler'

export default {
  extends: Bar,
  props: {
    categories: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { }
  },
  mounted() {
    this.redraw()
  },
  watch: {
    categories() { this.redraw() },
    datasets() { this.redraw() },
  },
  methods: {
    redraw() {
      const cycler = new ColorCycler()
      for(const s of this.datasets) {
        s.backgroundColor = cycler.next()
      }
      this.renderChart({
        labels: this.categories,
        datasets: this.datasets,
      }, {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{ ticks: { beginAtZero: true } }],
          xAxes: [{ ticks: { autoSkip: false } }],
        },
      })
    },
  },
}
