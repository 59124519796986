
const NICE_COLORS = [
  '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd',
  '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf',
]

class ColorCycler {
  constructor(colors = NICE_COLORS) {
    this.colors = colors
    this.idx = 0
  }

  next() {
    const color = this.colors[this.idx]
    this.idx = this.idx + 1
    if(this.idx >= this.colors.length) {
      this.idx = 0
    }
    return color
  }
}

export { ColorCycler as default }
