<template>
  <div>
    <v-divider />
    <v-row class="my-3">
      <v-col style="flex-grow: 1">
        <div class="text-h6 mb-1"><slot name="title" /></div>
        <div><slot name="subtitle" /></div>
      </v-col>
      <v-col style="flex-grow: 0">
        <v-btn outlined @click.stop="toggleVisibility">{{
          show ? "Verbergen" : "Anzeigen"
        }}</v-btn>
      </v-col>
    </v-row>
    <div v-if="show">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Collapser',
  props: {
    initialOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: this.initialOpen,
    }
  },
  methods: {
    toggleVisibility() {
      this.show = !this.show
      this.$emit('toggle', this.show)
    },
  },
}
</script>
