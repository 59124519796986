<template>
  <v-container fluid>
    <div class="text-h4 mb-2">Statistiken</div>
    <collapser>
      <template slot="title">Tourenbeteiligung nach Tourengruppe</template>
      <template slot="subtitle"
        >Durchschnittliche Tourenbeteiligung der letzten Jahre. Aufgeteilt nach
        Tourengruppe.</template
      >
      <template slot="content">
        <p>
          Die durchschnittliche Tourenbeteiligung wird jeweils aus der Summe von
          Leitern, Teilnehmern und Gästen pro Tour berechnet. Im laufenden Jahr
          werden nur Touren einbezogen, welche schon vorbei sind.
        </p>
        <bar-chart
          :categories="meanParticipationPerGroup.groups"
          :datasets="meanParticipationPerGroup.data"
        />
      </template>
    </collapser>
    <collapser>
      <template slot="title">Tourenbeteiligung nach Tourenart</template>
      <template slot="subtitle"
        >Durchschnittliche Tourenbeteiligung der letzten Jahre. Aufgeteilt nach
        Art der Tour.</template
      >
      <template slot="content">
        <p>
          Die durchschnittliche Tourenbeteiligung wird jeweils aus der Summe von
          Leitern, Teilnehmern und Gästen pro Tour berechnet. Im laufenden Jahr
          werden nur Touren einbezogen, welche schon vorbei sind.
        </p>
        <bar-chart
          :categories="meanParticipationPerKind.groups"
          :datasets="meanParticipationPerKind.data"
        />
      </template>
    </collapser>
    <collapser>
      <template slot="title">Altersverteilung Mitglieder &amp; Gäste</template>
      <template slot="subtitle"
        >Durchschnittliche Tourenbeteiligung der letzten Jahre. Aufgeteilt nach
        Art der Tour.</template
      >
      <template slot="content">
        <p>
          Die durchschnittliche Tourenbeteiligung wird jeweils aus der Summe von
          Leitern, Teilnehmern und Gästen pro Tour berechnet. Im laufenden Jahr
          werden nur Touren einbezogen, welche schon vorbei sind.
        </p>
        <hist-chart
          :labels="ageDistribution.bins"
          :datasets="ageDistribution.values"
        />
      </template>
    </collapser>
  </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import Collapser from '@/components/Collapser'
import BarChart from '@/components/charts/BarChart'
import HistChart from '@/components/charts/HistChart'

export default {
  name: 'Statistics',
  components: { BarChart, Collapser, HistChart },
  data() {
    return {
      meanParticipationPerGroup: { groups: [], data: [] },
      meanParticipationPerKind: { groups: [], data: [] },
      ageDistribution: { bins: [], values: [] },
    }
  },
  mounted() {
    this.loadMeanParticipationPerGroup()
    this.loadMeanParticipationPerKind()
    this.loadAgeDistribution()
  },
  methods: {
    loadMeanParticipationPerGroup() {
      axios.get('api/v1/statistics/meanParticipantsPerGroup')
        .then((resp) => {
          this.meanParticipationPerGroup = resp.data
        })
    },
    loadMeanParticipationPerKind() {
      axios.get('api/v1/statistics/meanParticipantsPerKind')
        .then((resp) => {
          this.meanParticipationPerKind = resp.data
        })
    },
    loadAgeDistribution() {
      axios.get('api/v1/statistics/ageDistribution')
        .then((resp) => {
          this.ageDistribution = resp.data
        })
    },
  },
}
</script>
